<template>
  <svg
    class="map country indonesia"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 169.52 71.33"
  >
    <g class="hover">
      <path
        d="M92.48,70.77l-1.56,.07-4.63-2.93,3.52-.85,1.83,1.3,1.17,1.24-.33,1.17Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.2px;
        "
      />
      <path
        d="M108.65,65.1l.33,.85-.07,1.24-2.67,3.13-3.26,.91-.39-.46,.46-1.43,1.83-2.54,3.78-1.7Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.2px;
        "
      />
      <path
        d="M82.83,61.78l1.24,1.11,2.35-.33,.78,1.76-4.37,.85-2.54,.59-2.02-.07,1.43-2.41h2.09l1.04-1.5Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.2px;
        "
      />
      <path
        d="M101.29,61.78l-.72,2.35-5.61,1.17-4.89-.52,.13-1.56,3-.85,2.22,1.24,2.48-.33,3.39-1.5Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.2px;
        "
      />
      <path
        d="M49,56.23l7.04,.46,.91-1.76,6.72,2.02,1.17,2.74,5.48,.78,4.37,2.48-4.3,1.56-3.98-1.7-3.33,.13-3.78-.33-3.39-.72-4.17-1.63-2.67-.39-1.56,.52-6.65-1.76-.52-1.76-3.33-.33,2.74-3.98,4.43,.26,2.87,1.63,1.56,.33,.39,1.43Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.2px;
        "
      />
      <path
        d="M145.42,53.89l-2.09,2.87-.13-3.13,.72-1.5,.85-1.43,.78,1.24-.13,1.96Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.2px;
        "
      />
      <path
        d="M118.43,42.35l-1.43,1.43-2.48-.78-.65-1.83,3.72-.2,.85,1.37Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.2px;
        "
      />
      <path
        d="M130.43,40.85l1.17,3.19-3-1.76-3.06-.33-2.15,.26-2.54-.13,.98-2.28,4.56-.2,4.04,1.24Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.2px;
        "
      />
      <path
        d="M169.42,38.76l-.65,13.63-1.04,13.69-3-3.46-3.65-.85-.98,1.17-4.69,.13,1.83-3.39,2.41-1.17-.65-4.56-1.5-3.46-6.98-3.59-3-.33-5.41-3.91-1.17,2.09-1.43,.33-.72-1.5,.07-1.83-2.74-2.09,4.04-1.5,2.61,.07-.26-1.11h-5.41l-1.43-2.48-3.26-.78-1.5-2.09,4.95-.98,1.89-1.37,5.93,1.7,.59,1.56,.85,6.78,3.72,2.48,3.26-4.43,4.3-2.48h3.26l3.13,1.43,2.67,1.5,3.98,.78Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.2px;
        "
      />
      <path
        d="M111.33,21.94l-2.93,4.17-2.8,.78-3.52-.78-6.19,.2-3.19,.59-.52,3.19,3.26,3.72,2.02-1.89,6.91-1.43-.33,1.89-1.63-.59-1.63,2.48-3.33,1.63,3.33,5.35-.72,1.43,3.13,4.82-.2,2.74-2.02,1.24-1.37-1.5,1.96-3.39-3.72,1.63-.85-1.17,.52-1.63-2.54-2.48,.46-4.04-2.54,1.24,.13,4.89-.13,6-2.41,.59-1.5-1.17,1.24-3.85-.39-4.04-1.56-.07-1.04-2.87,1.63-2.74,.59-3.33,1.96-6.32,.72-1.7,3.13-3.13,2.93,1.24,4.63,.59,4.24-.2,3.65-3,.65,.91Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.2px;
        "
      />
      <path
        d="M124.1,23.18l-.2,3.65-1.89-.46-.59,2.54,1.5,2.22-1.04,.52-1.43-2.67-1.11-5.35,.65-3.33,1.17-1.5,.33,2.28,2.22,.33,.39,1.76Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.2px;
        "
      />
      <path
        d="M53.69,19.46l.65,2.8,2.54,2.41,2.41-.85,2.35,.26,2.15-2.09,1.76-.39,3.52,1.17,3-.91,1.7-5.8,1.37-1.43,1.11-4.76h4.24l3.26,.72-1.96,3.78,2.87,3.91-.59,1.96,4.17,3.85-4.37,.52-1.17,2.87,.13,3.78-3.59,2.87-.26,4.17-1.63,6.39-.46-1.5-4.3,1.89-1.37-2.54-2.61-.26-1.83-1.37-4.43,1.56-1.24-2.09-2.48,.26-3-.46-.39-5.61-1.83-1.17-1.76-3.59-.52-3.65,.39-3.91,2.15-2.8Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.2px;
        "
      />
      <path
        d="M25.2,1.86l.91,.39,2.28,2.54,1.63,2.8,.39,2.8-.33,1.89,.39,1.43,.33,2.48,1.37,1.17,1.5,3.72v1.37l-2.61,.33-3.59-3.13-4.43-3.33-.52-2.15-2.22-2.8-.65-3.46-1.43-2.28,.26-3.06-.91-1.76,.59-.72,3.13,1.83,.39,2.15,2.41-.52,1.11-1.7Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.2px;
        "
      />
      <path
        d="M108.91,67.19l.07-1.24-.33-.85,.52-.98,3.19-.91,2.61-.2,1.17-.52,1.37,.52-1.43,1.17-3.98,1.83-3.19,1.17h0Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.2px;
        "
      />
      <path
        d="M20.31,19.07l3.78,.07,3.13,2.87,2.22,3.52,2.8,1.96-1.5,3.39,2.15,1.43,1.3,.13,.59,2.93,1.24,2.28,2.67,.39,1.7,2.67-1.11,5.22-.46,6.45-4.04,.07-2.93-3.46-4.63-3.46-1.5-2.54-2.67-3.39-1.76-3.13-2.74-5.87-3.19-3.52,1.37-2.91-.49-5.02,.49-4.59,3.59,4.5Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.2px;
        "
      />
      <path
        :class="`hover indonesia ${isActive('Indonesia')}`"
        data-loc="6,0"
        @click="quickViewShop('indonesia')"
        d="M15.36,27.09l-1.11-3.59-1.43-3.26-3.39-2.61-2.02-3.59-2.87-2.35L.49,7.08,.1,4.92l8.15,.98,3.39,4.11,5.09,4.56-.42,5.14,.42,4.47-1.37,2.91Z"
        style="
          stroke: #000;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.2px;
        "
      />
    </g>
    <g
      class="pin"
      :transform="`translate(${targetLoc[0]} ${targetLoc[1]}) scale(0.6)`"
    >
      <path
        fill="#000"
        d="M17.54,3.52A9.9,9.9,0,0,0,12,.21,9.91,9.91,0,0,0,.22,7.65,9.48,9.48,0,0,0,0,10.18c-.16,5.18,3.92,10,7.7,15,.93,1.15,2.23,2.42,2.29,2.57l2.08-2.46C16.82,19.08,23.74,10.05,17.54,3.52Zm-2.86,7.79A4.88,4.88,0,0,1,9,15.16l-.12,0a5,5,0,0,1-3.75-5.9,4.87,4.87,0,0,1,5.78-3.82A5,5,0,0,1,14.68,11.31Z"
      ></path>
      <path
        fill="#fff"
        d="M11.55,2.93A7.37,7.37,0,0,0,2.82,8.77a7.6,7.6,0,0,0,5.67,9l.18,0a7.37,7.37,0,0,0,8.55-5.87A7.59,7.59,0,0,0,11.55,2.93Zm3.13,8.38A4.88,4.88,0,0,1,9,15.16l-.12,0a5,5,0,0,1-3.75-5.9,4.87,4.87,0,0,1,5.78-3.82A5,5,0,0,1,14.68,11.31Z"
      ></path>
      <text x="25" y="20" class="small">{{ region }} - {{ country }}</text>
    </g>
    <text
      x="48"
      y="90"
      :class="`small ${isActive('Indonesia')}`"
      style="font-size: 15px"
    >
      Aceh, Indonesia
    </text>
  </svg>
</template>
<script>
export default {
  name: "Aceh",
  data() {
    return {
      targetLoc: [0, 0],
    };
  },
  props: {
    country: String,
    region: String,
  },
  mounted() {
    const target = document.querySelector(".map .active");
    this.targetLoc = [
      target.dataset.loc.split(",")[0],
      target.dataset.loc.split(",")[1],
    ];
    this.log("Map:#901", "Country", this.country, target);
  },
  methods: {
    isActive(query) {
      return query === this.country ? "active" : "";
    },
    quickViewShop(selection) {
      if (selection !== "shop") {
        this.$store.commit("saveFilterInStore", [selection]);
        this.$store.commit("saveActiveFilters", [1, 0, 0, 0]);
      }
      window.location = "#/user/shop";
    },
  },
};
</script>
